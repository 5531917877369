interface Price {
  price?: { displayValue: string }
  rrp?: { displayValue: string }
}

export const getPrice = (selectedVariant: {
  price?: Price | null
  priceRange?: string | null
  rrpRange?: string | null
}) => {
  const getDisplayValue = (value?: string) => value || '0.00'

  const price = getDisplayValue(selectedVariant.price?.price?.displayValue)
  const rrp = getDisplayValue(selectedVariant.price?.rrp?.displayValue)

  return {
    price: selectedVariant.priceRange || price,
    rrp: selectedVariant.rrpRange
      ? selectedVariant.rrpRange
      : selectedVariant.priceRange
      ? ''
      : rrp,
  }
}
