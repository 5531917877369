import { SiteProperties } from '@thg-commerce/enterprise-aurora-edge/horizon-schema'
import { ProductChoiceType } from '@thg-commerce/enterprise-config'
import {
  priceRange,
  rrpRange,
} from '@thg-commerce/enterprise-network/src/transformers/product/price'

import { HorizonOption, Price, Variant, VariantOptions } from '../types'

import { choiceMapTransformer } from './choiceMap/choiceMap'
import { singleOptionChoices } from './choiceMap/singleOptionChoices'
import { ChoiceIcon } from './choice'
import { choiceToVariantMap } from './choiceToVariantMap'
import { option as optionTransformer, TransformedOption } from './option'
import { variant as variantTransformer } from './variant'

export const UNSELECTED_KEY = 'unselected'
export interface ProductOptionsMapProps {
  variants: Variant[]
  siteProperties: SiteProperties
  horizonOptions: HorizonOption[]
  sortedHorizonOptionKeys: string[]
  showOutOfStockAsDisabled: boolean
  displayNotifyWhenInStock: boolean
  selectedVariant?: Variant
  productChoiceTypeMap?: {
    [key: string]: {
      type: ProductChoiceType
      propertyKey: string
      placeholderPropertyKey: string
    }
  }
  optionsOrder?: number[]
  optionKeysToFilter?: string[]
  optionKeysToApplyAttribute?: string[]
  icon?: ChoiceIcon | null
  linkedOnOptionKey?: string | null
  range?: {
    priceRange?: {
      minimum: Price
      maximum: Price
    }
    rrpRange?: {
      minimum: Price
      maximum: Price
    }
  }
}

export interface ChoiceSelectedVariantMap {
  [key: string]: VariantOptions
}

export type SingleProductOptionsMap = ChoiceSelectedVariantMap

export interface MultipleProductOptionsMap {
  [choiceKey: string]: ChoiceSelectedVariantMap
}

export type ProductOptionsMap =
  | SingleProductOptionsMap
  | MultipleProductOptionsMap

const addDefaultChoice = (
  hashmap: {},
  variant: Variant,
  options: TransformedOption[],
  range?: {
    priceRange?: {
      minimum: Price
      maximum: Price
    }
    rrpRange?: {
      minimum: Price
      maximum: Price
    }
  },
) => {
  const sku = variant.product?.sku || variant.sku
  const title = variant.product?.title || variant.title
  const masterProductVariant = {
    ...variant,
    sku,
    title,
    priceRange: priceRange(range || variant.product),
    rrpRange: rrpRange(range || variant.product),
    inStock: false,
    notifyWhenInStockEnabled: false,
  }

  hashmap[UNSELECTED_KEY] = {
    options,
    variant: variantTransformer(masterProductVariant, false),
  }

  return hashmap
}

export const productOptionsMap = (
  props: ProductOptionsMapProps,
): ProductOptionsMap => {
  const simpleProduct = props.horizonOptions.length === 0

  if (simpleProduct) {
    return {}
  }

  const productVariants = props.selectedVariant
    ? [props.selectedVariant]
    : props.variants

  const singleOptionProduct = props.horizonOptions.length === 1

  if (singleOptionProduct) {
    const optionKey = props.horizonOptions[0].key
    const choices = singleOptionChoices({
      variants: props.variants,
      showOutOfStockAsDisabled: props.showOutOfStockAsDisabled,
      optionKeysToApplyAttribute: props.optionKeysToApplyAttribute,
      horizonOptions: props.horizonOptions,
      icon: props.icon,
      siteProperties: props.siteProperties,
      displayNotifyWhenInStock: props.displayNotifyWhenInStock,
    })

    const options = [
      optionTransformer({
        choices,
        optionKey,
        optionKeysToApplyAttribute: props.optionKeysToApplyAttribute,
        siteProperties: props.siteProperties,
        optionsOrder: props.optionsOrder,
        productChoiceTypeMap: props.productChoiceTypeMap,
      }),
    ]

    const selectedChoiceToVariantMap = productVariants.reduce<
      SingleProductOptionsMap
    >((choiceToVariantMap, variant) => {
      if (!choiceToVariantMap[UNSELECTED_KEY]) {
        addDefaultChoice(choiceToVariantMap, variant, options, props.range)
      }

      const singleChoiceKey = variant.choices[0].key
      if (!choiceToVariantMap[singleChoiceKey]) {
        choiceToVariantMap[singleChoiceKey] = {
          options,
          variant: variantTransformer(variant, true),
        }
      }
      return choiceToVariantMap
    }, {})
    return selectedChoiceToVariantMap
  }

  const { choiceMap, fallbackChoiceMap } = choiceMapTransformer({
    variants: props.variants,
    linkedOnOptionKey: props.linkedOnOptionKey,
    horizonOptions: props.horizonOptions,
    showOutOfStockAsDisabled: props.showOutOfStockAsDisabled,
    optionKeysToApplyAttribute: props.optionKeysToApplyAttribute,
    icon: props.icon,
    siteProperties: props.siteProperties,
    orderedOptionKeys: props.sortedHorizonOptionKeys,
    displayNotifyWhenInStock: props.displayNotifyWhenInStock,
    range: props.range,
  })

  const selectedChoiceToVariantMap = choiceToVariantMap({
    choiceMap,
    fallbackChoiceMap,
    sortedHorizonOptionKeys: props.sortedHorizonOptionKeys,
    siteProperties: props.siteProperties,
    productChoiceTypeMap: props.productChoiceTypeMap,
    optionKeysToFilter: props.optionKeysToFilter,
    optionKeysToApplyAttribute: props.optionKeysToApplyAttribute,
    sortedHorizonOptions: props.horizonOptions,
    variants: productVariants,
    linkedOnOptionKey: props.linkedOnOptionKey,
    range: props.range,
  })
  return selectedChoiceToVariantMap
}
